import { QueryOptions, useMutation, useQuery, useQueryClient } from "react-query";
import api from ".";
import { CreateWatchFaceRequest } from "../../dto/request/create-watchface-request.dto";
import { TopWatchFacesResponse, TrendingWatchFacesResponse, WatchFaceGetDescriptionsRequest, WatchFaceGetDescriptionsResponse, WatchFaceGetTagsRequest, WatchFaceGetTagsResponse, WatchFacePaginationOptions, WatchFacesResponse } from "../../dto/request/watchfaces-request.dto";
import { ApiServiceErr, MutOpt } from "../../types/api.types";

export const RATE = 484

export const useGetWatchFaces = (
    { limit = 10, page = 1, category, categoryType, status }: WatchFacePaginationOptions,
    opt?: QueryOptions<WatchFacesResponse>
) => {

    return useQuery<WatchFacesResponse, ApiServiceErr>(
        ['watchfaces', limit, page, category, categoryType, status],
        async () => {
            const response = await api.get<WatchFacesResponse>(
                `/admin/watchface`,
                {
                    params: {
                        limit,
                        page,
                        category,
                        categoryType,
                        status,
                    }
                },
            );
            return response;
        },
        opt,
    );
};

export const useCreateWatchFace = (opt?: MutOpt<any>) => {
    const queryClient = useQueryClient();
    return useMutation<any, ApiServiceErr, CreateWatchFaceRequest>(async (data: any) => {

        const formData = new FormData();
        if (data.relativeOffset) {
            const value = data.relativeOffset / RATE
            data.relativeOffset = parseFloat(value.toFixed(16))
        }
        if (data.wfTimeRelativeSize) {
            const value = data.wfTimeRelativeSize / RATE
            data.wfTimeRelativeSize = parseFloat(value.toFixed(16))
        }
        Object.keys(data).forEach(key => {
            const value = data[key];
            if (value !== undefined) {
                if (Array.isArray(value)) {
                    if (value.length === 1 && key !== 'slots') {
                        formData.append(`${key}[]`, value[0]);
                        return
                    }
                    for (var i = 0; i < value.length; i++) {
                        formData.append(`${key}`, value[i]);
                    }

                } else if (typeof value === 'object' && value !== null && !(value instanceof File)) {
                    formData.append(key, JSON.stringify(value))
                }
                else {
                    formData.append(key, value)
                }

            }
        });
        data.isFree = Boolean(data.isFree);
        data.isApproved = Boolean(data.isApproved);

        const response = await api.post(`/admin/watchface`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    }, {
        ...opt,
        onSuccess: (data: any, variables: unknown, context: unknown) => {
            queryClient.invalidateQueries("watchfaces");
            opt?.onSuccess?.(data, variables, context);
        }
    })
};

export const useUpdateWatchFace = (opt?: MutOpt<any>) => {
    const queryClient = useQueryClient();

    return useMutation<any, ApiServiceErr, { id: string, data: any }>(async ({ id, data }) => {

        if (data.relativeOffset) {
            const value = data.relativeOffset / RATE
            data.relativeOffset = parseFloat(value.toFixed(16))
        }
        if (data.wfTimeRelativeSize) {
            const value = data.wfTimeRelativeSize / RATE
            data.wfTimeRelativeSize = parseFloat(value.toFixed(16))
        }
        data.isFree = Boolean(data.isFree);
        data.isApproved = Boolean(data.isApproved);

        const formData = new FormData();
        Object.keys(data).forEach(key => {
            const value = data[key];
            if (value !== undefined) {
                if (Array.isArray(value)) {
                    if (value.length === 1 && key !== 'slots') {
                        formData.append(`${key}[]`, value[0]);
                        return
                    }
                    for (var i = 0; i < value.length; i++) {
                        formData.append(`${key}`, value[i]);
                    }
                } else if (typeof value === 'object' && value !== null && !(value instanceof File)) {
                    formData.append(key, JSON.stringify(value))
                }
                else {
                    formData.append(key, value)
                }

            }
        });

        const response = await api.put(`/admin/watchface/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    }, {
        ...opt,
        onSuccess: (data: any, variables: {
            id: string;
            data: any;
        }, context: unknown) => {
            queryClient.invalidateQueries("watchfaces");
            opt?.onSuccess?.(data, variables, context);
        }
    });
}

export const useDeleteWatchFace = (opt?: MutOpt<any>) => {
    const queryClient = useQueryClient();

    return useMutation<any, ApiServiceErr, string>(async (id) => {

        const response = await api.delete(`/admin/watchface/${id}`);
        return response;
    }, {
        ...opt,
        onSuccess: (data: any, id: string, context: unknown) => {
            queryClient.invalidateQueries("watchfaces");
            opt?.onSuccess?.(data, id, context);
        }
    });
}


export const useGetTopWatchFaces = (
    { categoryType }: { categoryType?: string },
    opt?: QueryOptions<TopWatchFacesResponse>
) => {

    return useQuery<TopWatchFacesResponse, ApiServiceErr>(
        ['topWatchfaces', { categoryType }],
        async () => {
            const response = await api.get<TopWatchFacesResponse>(
                `/admin/watchface/top`,
                {
                    params: {
                        categoryType,
                    }
                }
            );
            return response;
        },
        opt,

    );
};

export const useGetTrendingWatchFaces = (
    { categoryType }: { categoryType?: string },
    opt?: QueryOptions<TrendingWatchFacesResponse>
) => {

    return useQuery<TrendingWatchFacesResponse, ApiServiceErr>(
        ['trendingWatchfaces', { categoryType }],
        async () => {
            const response = await api.get<TrendingWatchFacesResponse>(
                `/admin/watchface/trending`,
                {
                    params: {
                        categoryType,
                    }
                }
            );
            return response;
        },
        opt,

    );
};


export const useGenerateTopWatchFaces = (opt?: MutOpt<any>) => {
    const queryClient = useQueryClient();

    return useMutation<any, ApiServiceErr>(async (type) => {

        const response = await api.post(`/admin/watchface/top/generate`, { type });
        return response.data;
    },
        {
            onSuccess: (data: any, variables: void, context: unknown) => {
                queryClient.invalidateQueries("topWatchfaces");
                opt?.onSuccess?.(data, variables, context);
            }

        }

    )
}

export const useGenerateTrendingWatchFaces = (opt?: MutOpt<any>) => {
    const queryClient = useQueryClient();

    return useMutation<any, ApiServiceErr>(async () => {

        const response = await api.post(`/admin/watchface/trending/generate`, {});
        return response.data;
    },
        {
            onSuccess: (data: any, variables: void, context: unknown) => {
                queryClient.invalidateQueries("topWatchfaces");
                opt?.onSuccess?.(data, variables, context);
            }

        }

    )
}

export const useGetTagsByCategory = (
    { category }: WatchFaceGetTagsRequest,
    opt?: QueryOptions<WatchFaceGetTagsResponse>
) => {

    return useQuery<WatchFaceGetTagsResponse, ApiServiceErr>(
        ['watchfacesTags', category],
        async () => {
            const response = await api.get<WatchFaceGetTagsResponse>(
                `/admin/watchface/tags-by-category/${category}`,
            );
            return response;
        },
        {
            ...opt,
            enabled: Boolean(category)
        },
    );
};

export const useGetDescriptionsByCategory = (
    { category }: WatchFaceGetDescriptionsRequest,
    opt?: QueryOptions<WatchFaceGetDescriptionsResponse>
) => {

    return useQuery<WatchFaceGetDescriptionsResponse, ApiServiceErr>(
        ['watchfacesDescriptions', category],
        async () => {
            const response = await api.get<WatchFaceGetDescriptionsResponse>(
                `/admin/watchface/descriptions-by-category/${category}`,
            );
            return response;
        },
        {
            ...opt,
            enabled: Boolean(category)
        },
    );
};